import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import MovieTrailer from "./MovieTrailer";

const API_KEY = process.env.REACT_APP_API_KEY;

const styles = {
    banner: {
        position: 'relative',
        color: '#fff',
        backgroundSize: 'cover', 
        backgroundPosition: 'center',
        height: "47vw",
        width: "auto"
    },
    bannerContents: {
        marginLeft: '30px',
        paddingTop: '140px',
        height: '80%',
        display: "flex",
    },
    bannerTitle: {
        fontWeight: '800',
        marginBottom: '10px',
        fontSize: '4vw',
        maxWidth: '600px',
        lineHeight: '1.1',
        textShadow: '2px 2px 4px rgba(0,0,0,.25)'
    },
    bannerOverview: {
        width: '45rem',
        lineHeight: '1.3',
        marginTop: '16px',
        marginBottom: '16px',
        maxWidth: '600',
        fontSize: '2vh',
        textShadow: '2px 2px 4px rgba(0,0,0,.45)'
    },
    bannerFade: {
        height: '7.4rem',
    },
};

const Banner = () => {
    const [movie, setMovie] = useState({});
    const [showTrailer, setShowTrailer] = useState(false);

    useEffect(() => {
        const fetchBannerMovie = async () => {
            try {
                const response = await axios.get(
                `https://api.themoviedb.org/3/trending/all/week?api_key=${API_KEY}`
                );
                const randomIndex = Math.floor(Math.random() * response.data.results.length);
                setMovie(response.data.results[randomIndex]);
                setShowTrailer(true);
            } catch (error) {
                console.error('Error fetching banner movie:', error);
            }
        };
        fetchBannerMovie();
    }, []);

    return (
        <header
            style={{
                ...styles.banner,
                backgroundImage: `url("https://image.tmdb.org/t/p/original/${movie?.backdrop_path}")`,
            }}
        >
        <div style={styles.bannerContents}>
            <Container>
            <Row>
                <Col>
                    <Typography variant="h3" style={styles.bannerTitle}>
                        {movie?.title || movie?.name || movie?.original_name}
                    </Typography>
                    <div>
                        {showTrailer && <MovieTrailer movie={movie} />}
                    </div>
                    <Typography
                        variant="body1"
                        style={styles.bannerOverview}
                    >
                        {movie?.overview}
                    </Typography>  
                </Col>
            </Row>
            </Container>
        </div>
        <div style={styles.bannerFade} />
        </header>
    );
};

    export default Banner;