import React, { useEffect, useState, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import { Container, Card } from 'react-bootstrap';
import MovieTrailer from "./MovieTrailer";

const Row = ({ title, fetchUrl, isLargeRow }) => {
  const [movies, setMovies] = useState([]);
  const [hoveredMovieId, setHoveredMovieId] = useState(null);
  const scrollContainerRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(fetchUrl);
        setMovies(response.data.results || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [fetchUrl]);

  const containerStyles = {
    backgroundColor: 'black',
    padding: '1rem',
    overflow: 'hidden', 
  };

  const titleStyles = {
    fontSize: '1.5rem',
    color: 'white',
    padding: '1rem',
  };

  const cardContainerStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: '1rem',
  };

  const imgStyles = {
    width: '200px',
    height: '300px',
    transition: 'transform 0.6s',
    overflow: 'hidden', 
  };

  if (isLargeRow) {
    imgStyles.width = '300px';
    imgStyles.height = '450px';
  }

  const handleScroll = (direction) => {
    setIsScrolling(true);
    const scrollStep = direction === 'right' ? 10 : -10;
    const scrollInterval = setInterval(() => {
      scrollContainerRef.current.scrollLeft += scrollStep;
    }, 10);

    setTimeout(() => {
      clearInterval(scrollInterval);
      setIsScrolling(false);
    }, 300);
  };

  return (
    <Container fluid style={containerStyles}>
      <h2 style={titleStyles}>{title}</h2>

      <div
        ref={scrollContainerRef}
        style={{
          display: 'flex',
          justifyContent: 'center',
          overflowX: 'hidden', 
        }}
        onMouseEnter={() => handleScroll('right')}
        onMouseLeave={() => handleScroll('left')}
      >
        {movies.map((movie) => (
          <div
            key={movie.id}
            style={cardContainerStyles}
            onMouseEnter={() => setHoveredMovieId(movie.id)}
            onMouseLeave={() => setHoveredMovieId(null)}
          >
            <Card>
              <Card.Img
                variant="top"
                src={`https://image.tmdb.org/t/p/original${movie.poster_path}`}
                alt={movie.title}
                style={{
                  ...imgStyles,
                  transform: `scale(${hoveredMovieId === movie.id ? 1.05 : 1})`,
                }}
              />
              {hoveredMovieId === movie.id && (
                <Card.Body>
                  <Card.Title style={{ justifyContent: "center" }}>{movie.title}</Card.Title>
                  <MovieTrailer movie={movie} />
                </Card.Body>
              )}
            </Card>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Row;
