import React, { useState } from "react";
import YouTube from "react-youtube";
import movieTrailer from "movie-trailer";
import { Button, Modal } from "react-bootstrap"; 

const MovieTrailer = ({ movie }) => {
    const [trailerUrl, setTrailerUrl] = useState("");
    const [showModal, setShowModal] = useState(false);

    const handleCloseModal = () => {
        setTrailerUrl("");
        setShowModal(false);
    };

    const handleClick = async () => {
        try {
            const url = await movieTrailer(movie?.title || movie?.name || "");
            console.log("Trailer URL:", movie.title); 
            const urlParams = new URLSearchParams(new URL(url).search);
            const videoId = urlParams.get("v");
            setTrailerUrl(videoId);
            setShowModal(true);
        } catch (error) {
            console.log(error);
        }
    };

    const opts = {
        height: "390",
        width: "100%",
        playerVars: {
        autoplay: 1,
        },
    };

    const modalBodyStyles = {
        backgroundColor: "black",
        color: "white",
        padding: "20px",
    };

    return (
        <div>
            <Button variant="dark" onClick={handleClick}>
                Watch Trailer
            </Button>
            <Modal show={showModal} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{movie.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={modalBodyStyles}>
                    {trailerUrl && <YouTube videoId={trailerUrl} opts={opts}/>}    
                    <p>{movie.overview}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MovieTrailer;
