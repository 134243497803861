import React, { useState } from "react";
import Image from "react-bootstrap/Image";
import Nav from "react-bootstrap/Nav";
import FormControl from "react-bootstrap/FormControl";
import Avatar from "@mui/material/Avatar";
import Navbar from "react-bootstrap/Navbar";

const headerStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    backgroundColor: "#111"
};

const logoStyles = {
    width: "100px",
    objectFit: "contain",
};

const navItemStyles = {
    cursor: "pointer",
    marginRight: "20px",
    color: "#fff",
};

const searchBarStyles = {
    flex: 1,
    maxWidth: "600px",
    marginLeft: "20px",
};

const searchInputStyles = {
    width: "100%",
    padding: "10px",
    fontSize: "1rem",
    backgroundColor: "white",
    color: "#333",
    border: "none",
};

const avatarStyles = {
    marginLeft: "20px",
};

const Header = () => {
    const [search, setSearch] = useState("");

    const handleSearch = (event) => {
        event.preventDefault();
        console.log("Searching for:", search);
        setSearch(event.target.value);
    }


    return (
    <header style={headerStyles}>
        <Navbar variant="dark" className="justify-content-between" 
            style={{ width: "100%", background: "transparent", boxShadow: "none" }}>
        <Navbar.Brand>
            <Image
                style={logoStyles}
                src="https://upload.wikimedia.org/wikipedia/commons/0/08/Netflix_2015_logo.svg"
                alt="Netflix Logo"
            />
        </Navbar.Brand>
        <Nav className="header-nav">
            <Nav.Link style={navItemStyles}>Home</Nav.Link>
            <Nav.Link style={navItemStyles}>TV Shows</Nav.Link>
            <Nav.Link style={navItemStyles}>Movies</Nav.Link>
            <Nav.Link style={navItemStyles}>New & Popular</Nav.Link>
            <Nav.Link style={navItemStyles}>My List</Nav.Link>
        </Nav>
        <div style={searchBarStyles}>
            <FormControl
                style={searchInputStyles}
                type="text"
                placeholder="Search for a movie or TV show"
                className="searchInput"
                value={search}
                onChange={handleSearch}
            />
        </div>
        <div style={avatarStyles}>
            <Avatar
                className="header-avatar"
                src="https://randomuser.me/api/portraits/women/74.jpg"
                alt="User Avatar"
            />
        </div>
        </Navbar>
    </header>
    );
};

export default Header;
