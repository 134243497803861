import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Header from './components/Header';
import Row from './components/Row';
import Banner from './components/Banner';

const API_KEY = process.env.REACT_APP_API_KEY;

function App() {

    return (
        <BrowserRouter>
            <div data-testid='app'>
                    <Header />
                <div>
                    <Banner />
                </div>
                <Row
                    title="Trending Now" 
                    fetchUrl={`https://api.themoviedb.org/3/trending/movie/week?api_key=${API_KEY}`} 
                />
                <Row
                    title="Popular Movies"
                    fetchUrl={`https://api.themoviedb.org/3/movie/popular?api_key=${API_KEY}`}
                />
                <Row
                    title="Comedy Movies"
                    fetchUrl={`https://api.themoviedb.org/3/discover/movie?api_key=${API_KEY}&with_genres=35`}
                />
                <Row
                    title="Family Movies"
                    fetchUrl={`https://api.themoviedb.org/3/discover/movie?api_key=${API_KEY}&with_genres=10751`}
                />
                <Row
                    title="Science Fiction Movies"
                    fetchUrl={`https://api.themoviedb.org/3/discover/movie?api_key=${API_KEY}&with_genres=878`}
                />
            </div>
        </BrowserRouter>
    );
}

export default App;

